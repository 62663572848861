@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@200;400;700;800&display=swap');

@import 'custom';
@import 'components/auth';
@import 'components/photo';
@import 'components/items';
@import 'components/editor';
@import 'components/audio';

body {
  margin: 0;
  font-family: 'Noto Serif', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

