body {
    background-color: #f9f9f9;
}

.container-main {
    padding-bottom: 5em;
}

a {
    color: rgba(11, 38, 33, 0.68);
    &:hover {
        color: #0b2621;
    }
}

.main-color {
    color: #0b2621;
}


.form-check-input {
    margin-top: 6px;
    &:checked {
        background-color: #0b2621;
        border-color: #0b2621;
    }
}

.form-check-label {
    font-size: 0.85em;
}

.bg-dark {
    background-color: #0b2621 !important;
  }

.card-simple {
    box-shadow: rgba(0,0,0,.12) 0px 6px 16px !important;
    margin: 0 0 16px 0px !important;
    padding: 16px !important;
    border-radius: 12px !important;
    border: none;
    //transition: transform .3s ease;
    &:hover {
        box-shadow: rgba(0,0,0,.24) 0px 6px 16px !important;
        //cursor: pointer;
        //transform: scale(1.01);
    }
  }

.truncate-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-text-lines {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}



.no-link {
    text-decoration: none!important;
}

.modal-footer, .modal-header {
    border: none;
}

.btn {
    @media (max-width: 576px) {
        min-width: 130px;
    }
    @media (min-width: 576px) {
        min-width: 150px;
    }
    @media (min-width: 767.98px) {
        min-width: 200px;
    }
}

.text-bold-extra, .modal-title, h1, h2, h3, h4 {
    font-weight: 800;
}

.text-bold, h5 {
    font-weight: 600;
}

.small {
    color: #808080
}

.text-small {
    font-size: 0.85em;  
}

.small-extra {
    font-size: 0.6em;
    color: #808080
}

.line-height-9 {
    line-height: 0.9;
}

.input-file {
    display: none;
}

.pointer {
    cursor: pointer;
}

hr {
    opacity: .1!important;
}

.btn-light {
    --bs-btn-bg: #e5e5e6;
}

.notActive {
    filter: opacity(0.65);
    background-color: #fdfdfd;
}

.vh-100 {
    min-height: 100vh;
}

.prevent-select {
    user-select: none;
}

.frame-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.frame-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}