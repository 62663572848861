.editor {
    .h-full {
        min-height: calc(100vh - 250px)!important;
    }
}

.rdw-image-modal {
    width: 335px;
}

.rdw-image-modal-btn, .rdw-link-modal-btn {
    width: 100px;
}

.rdw-link-modal {
    width: 335px;
    height: 255px;
}

.rdw-option-wrapper {
    min-width: 30px;
    height: 30px;
}