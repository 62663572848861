.rhap_container {
    background-color: #0b26210a;
    box-shadow: none;
    border-radius: 5px;
}

.rhap_repeat-button {
    display: none;
}

.rhap_volume-controls, .rhap_additional-controls {
    @media (max-width: 350px) {
        display: none;
    }
}

.rhap_controls-section {
    @media (max-width: 350px) {
        justify-content: center !important;
    }
}

.playlist {
    background-color: #eff0f0;
    //border-radius: 0.3em;
    padding-top: 1em;
    margin-bottom: 1em;

    .track {
        padding: 0.5em 1em;
        cursor: pointer;
        border-top: 1px solid #c8c8c8;

        &__name {
            width: calc(100%);
            //padding-right: 1.5em;
        }

        &:hover:not(.playing) {
            background-color: #ececec;
        }
        position: relative;
        z-index: 1;
    }

    .playing {
        background-color: #212529;
        color: #fff;
    }

    .loader {
        display: block;
        top: -7px;
        position: relative;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //height: 100%;
        padding-right: 0.5em;
    }

    .delete {
        display: block;
        top: -7px;
        position: relative;
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0);
        }
        z-index: 2;
    }
    
    #wave {
        height: 18px;
        width: 20px;
        fill: #fff;
    }
    
    @for $i from 1 through 9 {
        #Line_#{$i} {
            animation: pulse 1s infinite;
            animation-delay: $i * .15s;
        }
    }
    
    @keyframes pulse {
        0% {
            transform: scaleY(1);
            transform-origin: 50% 50%;
        }
        
        50% {
            transform: scaleY(.7);
            transform-origin: 50% 50%;
        }
        
        100% {
            transform: scaleY(1);
            transform-origin: 50% 50%;
        }
    }
}